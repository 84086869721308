export const LOGIN_API_CALL = "LOGIN_API_CALL";
export const LOGOUT_API_CALL = "LOGOUT_API_CALL";
export const SAVE_LOGIN_USER_DATA = "SAVE_LOGIN_USER_DATA";
export const SAVE_USER_FILTER_DATA = "SAVE_USER_FILTER_DATA";
export const SAVE_ORG_FILTER_DATA = "SAVE_ORG_FILTER_DATA";
export const SAVE_LIST_FILTER_DATA = "SAVE_LIST_FILTER_DATA";

export const SET_LOADER = "SET_LOADER";
export const ORG_LOADER = "ORG_LOADER";
export const SET_BUTTON_LOADER = "SET_BUTTON_LOADER";

export const LINK_SEND = "LINK_SEND";

//user actions

export const USER_LIST_API_CALL = "USER_LIST_API_CALL";
export const SAVE_USER_LIST_DATA = "SAVE_USER_LIST_DATA";
export const CREATE_USER_API_CALL = "CREATE_USER_API_CALL";
export const VIEW_USER_API_CALL = "VIEW_USER_API_CALL";
export const DELETE_USER_API_CALL = "DELETE_USER_API_CALL";
export const BLOCK_USER_API_CALL = "BLOCK_USER_API_CALL";
export const SAVE_SINGLE_USER_DATA = "SAVE_SINGLE_USER_DATA";
export const UPDATE_SINGLE_USER_DATA = "UPDATE_SINGLE_USER_DATA";
export const TOTAL_USER_COUNT = "TOTAL_USER_COUNT";
export const PER_PAGE_USER_COUNT = "PER_PAGE_USER_COUNT";
export const VIEW_LOG_HISTORY_API_CALL = "VIEW_LOG_HISTORY_API_CALL";
export const SAVE_LOG_HISTORY_DATA = "SAVE_LOG_HISTORY_DATA";
export const GET_ORG_LIST_IN_SINGLE_USER_API_CALL =
  "GET_ORG_LIST_IN_SINGLE_USER_API_CALL";
export const SAVE_ORG_LIST_IN_SINGLE_USER_DATA =
  "SAVE_ORG_LIST_IN_SINGLE_USER_DATA";
export const UNBLOCK_USER_API_CALL = "UNBLOCK_USER_API_CALL";
export const SEND_PASSWORD_LINK_API_CALL = "SEND_PASSWORD_LINK_API_CALL";

//organization actions
export const ORGANIZATION_CITIES_GET_API_CALL = "ORGANIZATION_CITIES_GET";
export const ORGANIZATION_LIST_API_CALL = "ORGANIZATION_LIST_API_CALL";
export const ACTION_SAVE_CITIES_DATA = "ACTION_SAVE_CITIES_DATA";
export const ORGANIZATION_ZIP_CODE = "ORGANIZATION_ZIP_CODE";
export const ACTION_SAVE_ZIP_CODE = "ACTION_SAVE_ZIP_CODE";
export const APPROVE_ORGANIZATION_API_CALL = "APPROVE_ORGANIZATION_API_CALL";
export const CREATE_ORGANIZATION_API_CALL = "CREATE_ORGANIZATION_API_CALL";
export const ADD_TAG_API_CALL = "ADD_TAG_API_CALL";
export const ADD_AUDIENCE_API_CALL = "ADD_AUDIENCE_API_CALL";
export const ADD_RECIPIENT_API_CALL = "ADD_RECIPIENT_API_CALL";
export const DOWNLOAD_ALL_RECIPIENT_API_CALL =
  "DOWNLOAD_ALL_RECIPIENT_API_CALL";
export const GET_TEMPLATE_API_CALL = "GET_TEMPLATE_API_CALL";
export const GET_CREATED_TEMPLATE_API_CALL = "GET_CREATED_TEMPLATE_API_CALL";
export const GET_CAMPAIGN_API_CALL = "GET_CAMPAIGN_API_CALL";
export const ADD_USER_ORG_API_CALL = "ADD_USER_ORG_API_CALL";
export const REMOVE_USER_ORG_API_CALL = "REMOVE_USER_ORG_API_CALL";
export const VIEW_ORGANIZATION_API_CALL = "VIEW_ORGANIZATION_API_CALL";
export const DELETE_ORGANIZATION_API_CALL = "DELETE_ORGANIZATION_API_CALL";
export const BLOCK_ORGANIZATION_API_CALL = "BLOCK_ORGANIZATION_API_CALL";
export const UPDATE_ORGANIZATION_API_CALL = "UPDATE_ORGANIZATION_API_CALL";
export const SAVE_ORGANIZATION_LIST_DATA = "SAVE_ORGANIZATION_LIST_DATA";
export const SAVE_SINGLE_ORGANIZATION_DATA = "SAVE_SINGLE_ORGANIZATION_DATA";
export const TOTAL_ORG_COUNT = "TOTAL_ORG_COUNT";
export const PER_PAGE_ORG_COUNT = "PER_PAGE_ORG_COUNT";
export const GET_PROPERTY_LIST_API_CALL = "GET_PROPERTY_LIST_API_CALL";
export const SAVE_PROPERTY_LIST_DATA = "SAVE_PROPERTY_LIST_DATA";
export const USED_EMAIL_FLAG = "USED_EMAIL_FLAG";
export const ALREAFY_USED_EMAIL_DATA = "ALREAFY_USED_EMAIL_DATA";
export const REMOVE_JOINED_ORG_API_CALL = "REMOVE_JOINED_ORG_API_CALL";
export const CHANGE_ADMIN_ORG_API_CALL = "CHANGE_ADMIN_ORG_API_CALL";
export const HIDE_ALL_LISTING_OF_ORG_API_CALL =
  "HIDE_ALL_LISTING_OF_ORG_API_CALL";
export const UNBLOCK_ORGANIZATION_API_CALL = "UNBLOCK_ORGANIZATION_API_CALL";
export const UPDATE_JOB_TITLE_API_CALL = "UPDATE_JOB_TITLE_API_CALL";
export const CHANGE_USER_FROM_PROPERTY_API_CALL =
  "CHANGE_USER_FROM_PROPERTY_API_CALL";
export const CHANGE_IMPORT_STATUS_API_CALL = "CHANGE_IMPORT_STATUS_API_CALL";
export const CHANGE_IMPORT_PROFILE_STATUS_API_CALL =
  "CHANGE_IMPORT_PROFILE_STATUS_API_CALL";
export const ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL =
  "ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL";
export const ACTION_SAVE_EMPLOYEE_JOB_TITLE = "ACTION_SAVE_EMPLOYEE_JOB_TITLE";
export const RUN_IMPORT_API_CALL = "RUN_IMPORT_API_CALL";
export const CONTACT_LINKING_TO_ORG_API_CALL =
  "CONTACT_LINKING_TO_ORG_API_CALL";
export const ACTION_POST_SUBJECT_API_CALL = "ACTION_POST_SUBJECT_API_CALL";
export const ACTION_GET_ALL_CAMPAIGN_API_CALL =
  "ACTION_GET_ALL_CAMPAIGN_API_CALL";

//list action call
export const TOTAL_LIST_COUNT = "TOTAL_LIST_COUNT";
export const PER_PAGE_LIST_COUNT = "PER_PAGE_LIST_COUNT";
export const UPDATE_PROPERTY_VISIBLE_API_CALL =
  "UPDATE_PROPERTY_VISIBLE_API_CALL";
export const GET_PROPERTY_DETAIL_API_CALL = "GET_PROPERTY_DETAIL_API_CALL";
export const EDIT_PROPERTY_STATUS_API_CALL = "EDIT_PROPERTY_STATUS_API_CALL";
export const GET_PROPERTY_VIEWS_API_CALL = "GET_PROPERTY_VIEWS_API_CALL";
export const GET_PRICE_HISTORY_API_CALL = "GET_PRICE_HISTORY_API_CALL";
export const GET_TRANSLATION_LIST_API_CALL = "GET_TRANSLATION_LIST_API_CALL";
export const DELETE_PROPERTY_API_CALL = "DELETE_PROPERTY_API_CALL";
export const EDIT_PROPERTY_API_CALL = "EDIT_PROPERTY_API_CALL";
export const ACTION_EDIT_PROPERTY_DOCUMENTS_API_CALL =
  "ACTION_EDIT_PROPERTY_DOCUMENTS_API_CALL";
export const ACTION_REMOVE_PROPERTY_IMAGES_API_CALL =
  "ACTION_REMOVE_PROPERTY_IMAGES_API_CALL";

export const SAVE_SINGLE_PROPERTY_DETAIL = "SAVE_SINGLE_PROPERTY_DETAIL";
export const SAVE_PROPERTY_VIEWS_DETAIL = "SAVE_PROPERTY_VIEWS_DETAIL";
export const SAVE_PRICE_HISTORY_DATA = "SAVE_PRICE_HISTORY_DATA";
export const SAVE_RENT_PREFIX_DATA = "SAVE_RENT_PREFIX_DATA";
export const SAVE_RENT_POSTFIX_DATA = "SAVE_RENT_POSTFIX_DATA";
export const SAVE_SELL_PREFIX_DATA = "SAVE_SELL_PREFIX_DATA";
export const SAVE_SELL_POSTFIX_DATA = "SAVE_SELL_POSTFIX_DATA";
export const SAVE_STATUS_DATA = "SAVE_STATUS_DATA";
export const SAVE_ADDRESSES_DATA = "SAVE_ADDRESSES_DATA";
export const CHANGE_ADMIN_PROPERTY_API_CALL = "CHANGE_ADMIN_PROPERTY_API_CALL";

//lang actions
export const LANG_LIST_API_CALL = "LANG_LIST_API_CALL";
export const LANG_LABEL_LIST_API_CALL = "LANG_LABEL_LIST_API_CALL";
export const UPDATE_LANG_LABEL_API_CALL = "UPDATE_LANG_LABEL_API_CALL";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SAVE_LANG_FILTER_DATA = "SAVE_LANG_FILTER_DATA";
export const SAVE_LANG_LIST_DATA = "SAVE_LANG_LIST_DATA";
export const SAVE_LANG_LABEL_LIST_DATA = "SAVE_LANG_LABEL_LIST_DATA";
export const TOTAL_LANG_COUNT = "TOTAL_LANG_COUNT";
export const PER_PAGE_LANG_COUNT = "PER_PAGE_LANG_COUNT";

//banner actions
export const BANNER_LIST_API_CALL = "BANNER_LIST_API_CALL";
export const SAVE_BANNER_DATA = "SAVE_BANNER_DATA";
export const ADD_BANNER_API_CALL = "ADD_BANNER_API_CALL";
export const DELETE_BANNER_API_CALL = "DELETE_BANNER_API_CALL";
export const VIEW_BANNER_API_CALL = "VIEW_BANNER_API_CALL";
export const SAVE_SINGLE_BANNER_DATA = "SAVE_SINGLE_BANNER_DATA";
export const SAVE_BANNER_FILTER_DATA = "SAVE_BANNER_FILTER_DATA";
export const TOTAL_BANNER_COUNT = "TOTAL_BANNER_COUNT";
export const PER_PAGE_BANNER_COUNT = "PER_PAGE_BANNER_COUNT";

//email actions
export const EMAIL_LIST_API_CALL = "EMAIL_LIST_API_CALL";
export const ADD_EMAIL_TEMPLATE_LOGO_API_CALL =
  "ADD_EMAIL_TEMPLATE_LOGO_API_CALL";
export const SAVE_EMAIL_DATA = "SAVE_EMAIL_DATA";
export const VIEW_EMAIL_API_CALL = "VIEW_EMAIL_API_CALL";
export const SAVE_SINGLE_EMAIL_DATA = "SAVE_SINGLE_EMAIL_DATA";
export const TOTAL_EMAIL_COUNT = "TOTAL_EMAIL_COUNT";
export const PER_PAGE_EMAIL_COUNT = "PER_PAGE_EMAIL_COUNT";
export const ADD_EMAIL_API_CALL = "ADD_EMAIL_API_CALL";
export const SAVE_EMAIL_FILTER_DATA = "SAVE_EMAIL_FILTER_DATA";

//system management
// branch and classification

export const ACTION_GLBOAL_STATE_FOR_CLOSE_CANVAS =
  "ACTION_GLBOAL_STATE_FOR_CLOSE_CANVAS";

export const ACTION_GET_BRANCH_TABLE_DATA_API_CALL =
  "ACTION_GET_BRANCH_TABLE_DATA_API_CALL";
export const ACTION_SAVE_BRANCH_TABLE_DATA = "ACTION_SAVE_BRANCH_TABLE_DATA";
export const ACTION_GET_BRANCH = "ACTION_GET_BRANCH";
export const ACTION_SAVE_BRANCH = "ACTION_SAVE_BRANCH";

export const ACTION_GET_BRANCH_NESTED_DATA = "ACTION_GET_BRANCH_NESTED_DATA";
export const ACTION_STORE_BRANCH_NESTED_DATA =
  "ACTION_STORE_BRANCH_NESTED_DATA";

export const ACTION_ADD_BRANCH_DATA = "ACTION_ADD_BRANCH_DATA";
export const ACTION_STORE_BRANCH_DATA = "ACTION_STORE_BRANCH_DATA";

export const ACTION_REMOVE_BRANCH_DATA = "ACTION_REMOVE_BRANCH_DATA";
export const ACTION_EDIT_BRANCH_DATA = "ACTION_EDIT_BRANCH_DATA";

///////////////////

export const ACTION_NESTED_CLASSIFICATION_DATA =
  "ACTION_NESTED_CLASSIFICATION_DATA";
export const ACTION_STORE_NESTED_CLASSIFICATION_DATA =
  "ACTION_STORE_NESTED_CLASSIFICATION_DATA";

export const ACTION_ADD_CLASSIFICATION_DATA = "ACTION_ADD_CLASSIFICATION_DATA";
export const ACTION_GET_TABLE_CLASSIFICATION_DATA =
  "ACTION_GET_TABLE_CLASSIFICATION_DATA";
export const ACTION_STORE_TABLE_CLASSIFICATION_DATA =
  "ACTION_STORE_TABLE_CLASSIFICATION_DATA";

export const ACTION_REMOVE_CLASSIFICATION_DATA = "ACTION_REMOVE_CLASSIFICATION";

export const ACTION_GET_SITE_SETTINGS_API_CALL =
  "ACTION_GET_SITE_SETTINGS_API_CALL";
export const ACTION_SAVE_GET_SITE_SETTINGS = "ACTION_SAVE_GET_SITE_SETTINGS";
export const ACTION_UPDATE_SITE_SETTINGS_API_CALL =
  "ACTION_UPDATE_SITE_SETTINGS_API_CALL";

//master table management
export const ACTION_MASTER_TABLE_GET_TRANSLATION_COLUMN_API_CALL =
  "ACTION_MASTER_TABLE_GET_TRANSLATION_COLUMN_API_CALL";

export const ACTION_SAVE_MASTER_TABLE_GET_TRANSLATION_COLUMN =
  "ACTION_SAVE_MASTER_TABLE_GET_TRANSLATION_COLUMN";

export const ACTION_GET_TRANSLATION_API_CALL =
  "ACTION_GET_TRANSLATION_API_CALL";

export const ACTION_SAVE_GET_TRANSLATION = "ACTION_SAVE_GET_TRANSLATION";
export const ACTION_ADD_TRANSLATION_API_CALL =
  "ACTION_ADD_TRANSLATION_API_CALL";
export const PER_PAGE_MASTER_TABLE_COUNT = "PER_PAGE_MASTER_TABLE_COUNT";
export const SAVE_TOTAL_MASTER_TABLE_COUNT = "SAVE_TOTAL_MASTER_TABLE_COUNT";
export const SAVE_MASTER_TABLE_FILTER_DATA = "SAVE_MASTER_TABLE_FILTER_DATA";

//discpunt apis
export const ACTION_GET_DISCOUNT_AND_EXTRAS_API_CALL =
  "ACTION_GET_DISCOUNT_AND_EXTRAS_API_CALL";
export const ACTION_DELETE_DISCOUNT_AND_EXTRAS_API_CALL =
  "ACTION_DELETE_DISCOUNT_AND_EXTRAS_API_CALL";
export const ACTION_SAVE_DISCOUNT_AND_EXTRAS =
  "ACTION_SAVE_DISCOUNT_AND_EXTRAS";
export const ACTION_ADD_DISCOUNT_AND_EXTRAS_API_CALL =
  "ACTION_ADD_DISCOUNT_AND_EXTRAS_API_CALL";
export const ACTION_SAVE_SINGLE_DISCOUNT_DATA =
  "ACTION_SAVE_SINGLE_DISCOUNT_DATA";
export const SAVE_DISCOUNT_FILTER_DATA = "SAVE_DISCOUNT_FILTER_DATA";
export const TOTAL_DISCOUNT_COUNT = "TOTAL_DISCOUNT_COUNT";

//subsciption apis

export const ACTION_GET_SUBSCIPTION_API_CALL =
  "ACTION_GET_SUBSCIPTION_API_CALL";
export const ACTION_DELETE_SUBSCIPTION_API_CALL =
  "ACTION_DELETE_SUBSCIPTION_API_CALL";
export const ACTION_SAVE_SUBSCIPTION = "ACTION_SAVE_SUBSCIPTION";
export const ACTION_CREATE_SUBSCRIPTION_API_CALL =
  "ACTION_CREATE_SUBSCRIPTION_API_CALL";
export const ACTION_SAVE_SINGLE_SUBSCIPTION_DATA =
  "ACTION_SAVE_SINGLE_SUBSCIPTION_DATA";
export const ACTION_EDIT_SINGLE_SUBSCIPTION_API_CALL =
  "ACTION_EDIT_SINGLE_SUBSCIPTION_API_CALL";
export const ACTION_REMOVE_SUBSCIPTION_PAYMENT_OPTIONS_API_CALL =
  "ACTION_REMOVE_SUBSCIPTION_PAYMENT_OPTIONS_API_CALL";
export const ACTION_REMOVE_SUBSCIPTION_DISCOUNTS_EXTRAS_OPTIONS_API_CALL =
  "ACTION_REMOVE_SUBSCIPTION_DISCOUNTS_EXTRAS_OPTIONS_API_CALL";
export const ACTION_EDIT_ACTIVE_SUBSCIPTION_API_CALL =
  "ACTION_EDIT_ACTIVE_SUBSCIPTION_API_CALL";
export const SAVE_SUBSCIPTION_FILTER_DATA = "SAVE_SUBSCIPTION_FILTER_DATA";
export const TOTAL_SUBSCIPTION_COUNT = "TOTAL_SUBSCIPTION_COUNT";
export const ACTION_ADD_SUBSCIPTION_IMAGE_API_CALL =
  "ACTION_ADD_SUBSCIPTION_IMAGE_API_CALL";
export const ACTION_CHANGE_SUBSCIPTION_ORDER_API_CALL =
  "ACTION_CHANGE_SUBSCIPTION_ORDER_API_CALL";
export const ACTION_GET_ALL_SUBSCIPTION_BY_ORG_API_CALL =
  "ACTION_GET_ALL_SUBSCIPTION_BY_ORG_API_CALL";
export const ACTION_SAVE_ALL_SUBSCIPTION_LIST =
  "ACTION_SAVE_ALL_SUBSCIPTION_LIST";

export const ACTION_GET_ALL_PAYMENT_OPTION_API_CALL =
  "ACTION_GET_ALL_PAYMENT_OPTION_API_CALL";
export const ACTION_SAVE_ALL_PAYMENT_OPTION = "ACTION_SAVE_ALL_PAYMENT_OPTION";

export const ACTION_CREATE_MANUAL_PAYMENT_API_CALL =
  "ACTION_CREATE_MANUAL_PAYMENT_API_CALL";
export const ACTION_STOP_SUBSCRIPTION_API_CALL =
  "ACTION_STOP_SUBSCRIPTION_API_CALL";
export const ACTION_EDIT_SUBSCRIPTION_IN_ORG_API_CALL =
  "ACTION_EDIT_SUBSCRIPTION_IN_ORG_API_CALL";
//branch-classification
export const TOTAL_BRANCH_COUNT = "TOTAL_BRANCH_COUNT";
export const PER_PAGE_BRANCH_COUNT = "PER_PAGE_BRANCH_COUNT";
export const TOTAL_CLASSIFICATION_COUNT = "TOTAL_CLASSIFICATION_COUNT";
export const PER_PAGE_CLASSIFICATION_COUNT = "PER_PAGE_CLASSIFICATION_COUNT";

// contact api
export const ACTION_ADD_CONTACT_API_CALL = "ACTION_ADD_CONTACT_API_CALL";
export const ACTION_DELETE_CONTACT_API_CALL = "ACTION_DELETE_CONTACT_API_CALL";

//country api
export const ACTION_GET_COUNTRIES_TABLE_DATA_API_CALL =
  "ACTION_GET_COUNTRIES_TABLE_DATA_API_CALL";
export const ACTION_SAVE_COUNTRIES_TABLE_DATA =
  "ACTION_SAVE_COUNTRIES_TABLE_DATA";
export const ACTION_SAVE_TOTAL_COUNTRIES_COUNT =
  "ACTION_SAVE_TOTAL_COUNTRIES_COUNT";
export const ACTION_ADD_COUNTRIES_ADD_API_CALL =
  "ACTION_ADD_COUNTRIES_ADD_API_CALL";
export const ACTION_REMOVE_COUNTRIES_DATA_API_CALL =
  "ACTION_REMOVE_COUNTRIES_DATA_API_CALL";

//Municipality api
export const ACTION_GET_MUNICIPALITY_TABLE_DATA_API_CALL =
  "ACTION_GET_MUNICIPALITY_TABLE_DATA_API_CALL";
export const ACTION_SAVE_MUNICIPALITY_TABLE_DATA =
  "ACTION_SAVE_MUNICIPALITY_TABLE_DATA";
export const ACTION_SAVE_TOTAL_MUNICIPALITY_COUNT =
  "ACTION_SAVE_TOTAL_MUNICIPALITY_COUNT";
export const ACTION_ADD_MUNICIPALITY_DATA_API_CALL =
  "ACTION_ADD_MUNICIPALITY_DATA_API_CALL";
export const ACTION_DELETE_MUNICIPALITY_DATA_API_CALL =
  "ACTION_DELETE_MUNICIPALITY_DATA_API_CALL";
//add  branch /classdification in single org
export const ACTION_ADD_AGENCY_BRANCH_DATA_API_CALL =
  "ACTION_ADD_AGENCY_BRANCH_DATA_API_CALL";
export const ACTION_ADD_AGENCY_CLASSIFICATION_DATA_API_CALL =
  "ACTION_ADD_AGENCY_CLASSIFICATION_DATA_API_CALL";

//District api
export const ACTION_GET_DISTRICT_TABLE_DATA_API_CALL =
  "ACTION_GET_DISTRICT_TABLE_DATA_API_CALL";
export const ACTION_SAVE_DISTRICT_TABLE_DATA =
  "ACTION_SAVE_DISTRICT_TABLE_DATA";
export const ACTION_SAVE_TOTAL_DISTRICT_COUNT =
  "ACTION_SAVE_TOTAL_DISTRICT_COUNT";
export const ACTION_ADD_DISTRICT_DATA_API_CALL =
  "ACTION_ADD_DISTRICT_DATA_API_CALL";
export const ACTION_DELETE_DISTRICT_DATA_API_CALL =
  "ACTION_DELETE_DISTRICT_DATA_API_CALL";

//Neighbourhood api
export const ACTION_GET_NEIGHBOURHOOD_TABLE_DATA_API_CALL =
  "ACTION_GET_NEIGHBOURHOOD_TABLE_DATA_API_CALL";
export const ACTION_SAVE_NEIGHBOURHOOD_TABLE_DATA =
  "ACTION_SAVE_NEIGHBOURHOOD_TABLE_DATA";
export const ACTION_SAVE_TOTAL_NEIGHBOURHOOD_COUNT =
  "ACTION_SAVE_TOTAL_NEIGHBOURHOOD_COUNT";
export const ACTION_ADD_NEIGHBOURHOOD_DATA_API_CALL =
  "ACTION_ADD_NEIGHBOURHOOD_DATA_API_CALL";
export const ACTION_DELETE_NEIGHBOURHOOD_DATA_API_CALL =
  "ACTION_DELETE_NEIGHBOURHOOD_DATA_API_CALL";

//CountryPart api
export const ACTION_GET_COUNTRYPART_TABLE_DATA_API_CALL =
  "ACTION_GET_COUNTRYPART_TABLE_DATA_API_CALL";
export const ACTION_SAVE_COUNTRYPART_TABLE_DATA =
  "ACTION_SAVE_COUNTRYPART_TABLE_DATA";
export const ACTION_SAVE_TOTAL_COUNTRYPART_COUNT =
  "ACTION_SAVE_TOTAL_COUNTRYPART_COUNT";
export const ACTION_ADD_COUNTRYPART_DATA_API_CALL =
  "ACTION_ADD_COUNTRYPART_DATA_API_CALL";
export const ACTION_DELETE_COUNTRYPART_DATA_API_CALL =
  "ACTION_DELETE_COUNTRYPART_DATA_API_CALL";
//city
export const ACTION_GET_CITY_API_CALL = "ACTION_GET_CITY_API_CALL";
export const ACTION_SAVE_CITY_DATA = "ACTION_SAVE_CITY_DATA";
export const ACTION_SAVE_TOTAL_CITY_COUNT = "ACTION_SAVE_TOTAL_CITY_COUNT";
export const ACTION_ADD_CITY_API_CALL = "ACTION_ADD_CITY_API_CALL";
export const ACTION_DELETE_CITY_API_CALL = "ACTION_DELETE_CITY_API_CALL";

// province
export const ACTION_GET_PROVINCE_API_CALL = "ACTION_GET_PROVINCE_API_CALL";
export const ACTION_SAVE_PROVINCE_DATA = "ACTION_SAVE_PROVINCE_DATA";
export const ACTION_SAVE_TOTAL_PROVINCE_COUNT =
  "ACTION_SAVE_TOTAL_PROVINCE_COUNT";
export const ACTION_ADD_PROVINCE_DATA_API_CALL =
  "ACTION_ADD_PROVINCE_DATA_API_CALL";
export const ACTION_DELETE_PROVINCE_DATA_API_CALL =
  "ACTION_DELETE_PROVINCE_DATA_API_CALL";

//zipcode

export const ACTION_GET_ZIPCODE_API_CALL = "ACTION_GET_ZIPCODE_API_CALL";
export const ACTION_SAVE_ZIPCODE_DATA = "ACTION_SAVE_ZIPCODE_DATA";
export const ACTION_SAVE_TOTAL_ZIPCODE_COUNT =
  "ACTION_SAVE_TOTAL_ZIPCODE_COUNT";
export const ACTION_ADD_ZIPCODE_API_CALL = "ACTION_ADD_ZIPCODE_API_CALL";
export const ACTION_DELETE_ZIPCODE_API_CALL = "ACTION_DELETE_ZIPCODE_API_CALL";

//contact log
export const ACTION_GET_CONTACT_LOG_API_CALL =
  "ACTION_GET_CONTACT_LOG_API_CALL";
export const ACTION_SAVE_CONTACT_LOG_DATA = "ACTION_SAVE_CONTACT_LOG_DATA";
export const ACTION_ADD_CONTACT_LOG_API_CALL =
  "ACTION_ADD_CONTACT_LOG_API_CALL";
export const ACTION_DELETE_CONTACT_LOG_API_CALL =
  "ACTION_DELETE_CONTACT_LOG_API_CALL";

// single org address module
export const ACTION_AUTO_SUGGEST_CITY_API_CALL =
  "ACTION_AUTO_SUGGEST_CITY_API_CALL";
export const ACTION_SAVE_AUTO_SUGGEST_CITY_DATA =
  "ACTION_SAVE_AUTO_SUGGEST_CITY_DATA";
export const ACTION_ADD_ADDRESS_ORG_API_CALL =
  "ACTION_ADD_ADDRESS_ORG_API_CALL";
export const ACTION_DELETE_ADDRESS_ORG_API_CALL =
  "ACTION_DELETE_ADDRESS_ORG_API_CALL";
//trnslation module
export const ACTION_GET_TRANSLATION_COLUMN_API_CALL =
  "ACTION_GET_TRANSLATION_COLUMN_API_CALL";
export const ACTION_SAVE_TRANSLATION_COLUMN = "ACTION_SAVE_TRANSLATION_COLUMN";
export const ACTION_SAVE_TOTAL_TRANSLATION_COLUMN_COUNT =
  "ACTION_SAVE_TOTAL_TRANSLATION_COLUMN_COUNT";
export const ACTION_ADD_EDIT_TRANSLATION_API_CALL =
  "ACTION_ADD_EDIT_TRANSLATION_API_CALL";
export const ACTION_SAVE_TRANSLATION_COLUMN_FILTER =
  "ACTION_SAVE_TRANSLATION_COLUMN_FILTER";
export const ACTION_SAVE_TRANSLATION_LANGUAGE_FILTER =
  "ACTION_SAVE_TRANSLATION_LANGUAGE_FILTER";

// internal banner
export const ACTION_ADD_INTERNAL_BANNER_API_CALL =
  "ACTION_ADD_INTERNAL_BANNER_API_CALL";
export const ACTION_GET_INTERNAL_BANNER_API_CALL =
  "ACTION_GET_INTERNAL_BANNER_API_CALL";
export const ACTION_SAVE_INTERNAL_BANNER_DATA =
  "ACTION_SAVE_INTERNAL_BANNER_DATA";
export const ACTION_DELETE_INTERNAL_BANNER_API_CALL =
  "ACTION_DELETE_INTERNAL_BANNER_API_CALL";
export const ACTION_GET_BANNER_URL_API_CALL = "ACTION_GET_BANNER_URL_API_CALL";
export const ACTION_SAVE_BANNER_URL_DATA = "ACTION_SAVE_BANNER_URL_DATA";
//city info
export const ACTION_CITY_INFO_API_CALL = "ACTION_CITY_INFO_API_CALL";
export const ACTION_CITY_INFO_SAVE_DATA = "ACTION_CITY_INFO_SAVE_DATA";
//vat module
export const ACTION_GET_VAT_API_CALL = "ACTION_GET_VAT_API_CALL";
export const ACTION_SAVE_VAT_DATA = "ACTION_SAVE_VAT_DATA";
export const ACTION_UPDATE_VAT_DATA = "ACTION_UPDATE_VAT_DATA";
export const ACTION_SAVE_TOTAL_VAT_DATA_COUNT =
  "ACTION_SAVE_TOTAL_VAT_DATA_COUNT";
// property banners
export const BANNER_CAMPAIGNS_API_CALL = "BANNER_CAMPAIGNS_API_CALL";
export const ACTION_SAVE_BANNER_CAMPAIGNS_LISTING =
  "ACTION_SAVE_BANNER_CAMPAIGNS_LISTING";
export const ACTION_SAVE_BANNER_CAMPAIGN_DATA =
  "ACTION_SAVE_BANNER_CAMPAIGN_DATA";
export const ACTION_DELETE_PROPERTY_BANNER_API_CALL =
  "ACTION_DELETE_PROPERTY_BANNER_API_CALL";
export const ACTION_VIEW_PROPERTY_BANNER_API_CALL =
  "ACTION_VIEW_PROPERTY_BANNER_API_CALL";
export const ACTION_SAVE_DELETE_PROPERTY_BANNER =
  "ACTION_SAVE_DELETE_PROPERTY_BANNER";
export const ACTION_SAVE_BANNER_DETAILS = "ACTION_SAVE_BANNER_DETAILS";
export const MAKE_BANNER_STOP_API_CALL = "MAKE_BANNER_STOP_API_CALL";

// invoices
export const INVOICES_API_CALL = "INVOICES_API_CALL";
export const ACTION_SAVE_INVOICE_LISTING = "ACTION_SAVE_INVOICE_LISTING";
export const ACTION_SAVE_INVOICE_DATA = "ACTION_SAVE_INVOICE_DATA";
export const ACTION_SAVE_TOKEN = "ACTION_SAVE_TOKEN";
export const ACTION_DELETE_INVOICE_API_CALL = "ACTION_DELETE_INVOICE_API_CALL";
export const ACTION_VIEW_INVOICE_API_CALL = "ACTION_VIEW_INVOICE_API_CALL";
export const ACTION_VIEW_TOKEN_API_CALL = "ACTION_VIEW_TOKEN_API_CALL";
export const ACTION_SAVE_DELETE_INVOICE = "ACTION_SAVE_DELETE_INVOICE";
export const ACTION_SAVE_INVOICE_DETAILS = "ACTION_SAVE_INVOICE_DETAILS";
export const HOLD_INVOICE_API_CALL = "HOLD_INVOICE_API_CALL";
export const RESEND_INVOICE_API_CALL = "RESEND_INVOICE_API_CALL";
export const ACTION_SAVE_ALL_CLASSIFICATIONS_OPTION =
  "ACTION_SAVE_ALL_CLASSIFICATIONS_OPTION";
export const ACTION_GET_ALL_CLASSIFICATIONS_API_CALL =
  "ACTION_GET_ALL_CLASSIFICATIONS_API_CALL";
export const ACTION_SAVE_ALL_PROVINCE_OPTION =
  "ACTION_SAVE_ALL_PROVINCE_OPTION";
export const ACTION_GET_ALL_PROVINCE_OPTION = "ACTION_GET_ALL_PROVINCE_OPTION";
export const ACTION_SAVE_ALL_CITY_OPTION = "ACTION_SAVE_ALL_CITY_OPTION";
export const ACTION_GET_ALL_CITY_OPTION = "ACTION_GET_ALL_CITY_OPTION";

//TAGS
export const ACTION_SAVE_CREATED_TAGS = "ACTION_SAVE_CREATED_TAGS";
export const ACTION_SAVE_CREATED_AUDIENCE = "ACTION_SAVE_CREATED_AUDIENCE";
export const ACTION_CREATE_NEW = "ACTION_CREATE_NEW";
export const ACTION_SAVE_SUBJECT = "ACTION_SAVE_SUBJECT";
export const ACTION_EMPTY_SUBJECTS_DATA = "ACTION_EMPTY_SUBJECTS_DATA";
export const ACTION_SAVE_ALL_CAMPAIGN = "ACTION_SAVE_ALL_CAMPAIGN";
export const ACTION_EMPTY_CONTENT = "ACTION_EMPTY_CONTENT";
export const ACTION_EMPTY_AUDIENCE_DATA = "ACTION_EMPTY_AUDIENCE_DATA";
export const ACTION_SAVE_CREATED_RECIPIENTS = "ACTION_SAVE_CREATED_RECIPIENTS";
export const ACTION_SAVE_DOWNLOADED_RECIPIENTS =
  "ACTION_SAVE_DOWNLOADED_RECIPIENTS";
export const ACTION_SAVE_CREATED_TEMPLATES = "ACTION_SAVE_CREATED_TEMPLATES";
export const ACTION_SAVE_NEW_CREATED_TEMPLATES =
  "ACTION_SAVE_NEW_CREATED_TEMPLATES";
export const ACTION_SAVE_NEW_CREATED_CAMPAIGN =
  "ACTION_SAVE_NEW_CREATED_CAMPAIGN";

//TAGS
export const ACTION_UPDATE_TAGS_SUCCESS = "ACTION_UPDATE_TAGS_SUCCESS";
export const ACTION_GET_ALL_TAGS_API_CALL = "ACTION_GET_ALL_TAGS_API_CALL";
export const ACTION_SAVE_ALL_TAGS_OPTION = "ACTION_SAVE_ALL_TAGS_OPTION";
export const ACTION_DELETE_TAG_SUCCESS = "ACTION_DELETE_TAG_SUCCESS";

//TEMPLATES
export const ACTION_GET_ALL_TEMPLATE_API_CALL =
  "ACTION_GET_ALL_TEMPLATE_API_CALL";
export const ACTION_SAVE_ALL_TEMPLATE_OPTION =
  "ACTION_SAVE_ALL_TEMPLATE_OPTION";
export const ACTION_UPDATE_TEMPLATE_SUCCESS = "ACTION_UPDATE_TEMPLATE_SUCCESS";
export const ACTION_DELETE_TEMPLATE_SUCCESS = "ACTION_DELETE_TEMPLATE_SUCCESS";
export const ACTION_DELETE_CAMPAIGN_SUCCESS = "ACTION_DELETE_CAMPAIGN_SUCCESS";

//SUPPLIERS
export const ACTION_DELETE_SUPPLIER_API_CALL =
  "ACTION_DELETE_SUPPLIER_API_CALL";
export const ACTION_VIEW_SUPPLIER_API_CALL = "ACTION_VIEW_SUPPLIER_API_CALL";
export const SUPPLIERS_API_CALL = "SUPPLIERS_API_CALL";
export const ACTION_SAVE_SUPPLIERS_DATA = "ACTION_SAVE_SUPPLIERS_DATA";
export const ACTION_SAVE_SUPPLIER_DATA = "ACTION_SAVE_SUPPLIER_DATA";
export const ACTION_SAVE_DELETE_SUPPLIER = "ACTION_SAVE_DELETE_SUPPLIER";

export const ACTION_ADD_LOCATION_API_CALL = "ACTION_ADD_LOCATION_API_CALL";
export const ACTION_DELETE_LOCATION_API_CALL =
  "ACTION_DELETE_LOCATION_API_CALL";

export const ACTION_ADD_PRICE_TABLE_API_CALL =
  "ACTION_ADD_PRICE_TABLE_API_CALL";
export const ACTION_DELETE_PRICE_TABLE_API_CALL =
  "ACTION_DELETE_PRICE_TABLE_API_CALL";

export const ADD_SUPPLIER_API_CALL = "ADD_SUPPLIER_API_CALL";
export const UPDATE_SUPPLIER_API_CALL = "UPDATE_SUPPLIER_API_CALL";

export const ACTION_ADD_CREDIT_API_CALL = "ACTION_ADD_CREDIT_API_CALL";

export const GET_LOCATION_LIST_API_CALL = "GET_LOCATION_LIST_API_CALL";
export const SAVE_LOCATION_LIST_DATA = "SAVE_LOCATION_LIST_DATA";
export const GET_TITLE_LIST_API_CALL = "GET_TITLE_LIST_API_CALL";
export const SAVE_TITLE_LIST_DATA = "SAVE_TITLE_LIST_DATA";

export const GET_ADVERTISING_SUPPLIER_LIST_API_CALL =
  "GET_ADVERTISING_SUPPLIER_LIST_API_CALL";
export const SAVE_ADVERTISING_SUPPLIER_LIST_DATA =
  "SAVE_ADVERTISING_SUPPLIER_LIST_DATA";

export const PER_PAGE_SUPPLIER_COUNT = "PER_PAGE_SUPPLIER_COUNT";
export const SAVE_SUPPLIER_FILTER_DATA = "SAVE_SUPPLIER_FILTER_DATA";

export const GET_SUPPLIER_META_DATA_API_CALL =
  "GET_SUPPLIER_META_DATA_API_CALL";

export const SAVE_SUPPLIER_TYPES_DATA = "SAVE_SUPPLIER_TYPES_DATA";
export const SAVE_PRICETABLE_TYPES_DATA = "SAVE_PRICETABLE_TYPES_DATA";
export const SAVE_PRICELINE_UNITS_DATA = "SAVE_PRICELINE_UNITS_DATA";

//MAGAZINES
export const ACTION_DELETE_MAGAZINE_API_CALL =
  "ACTION_DELETE_MAGAZINE_API_CALL";
export const ACTION_SAVE_DELETE_MAGAZINE = "ACTION_SAVE_DELETE_MAGAZINE";

export const ACTION_GET_MAGAZINE_API_CALL = "ACTION_GET_MAGAZINE_API_CALL";
export const ACTION_GET_MAGAZINES_API_CALL = "ACTION_GET_MAGAZINES_API_CALL";

export const ACTION_SAVE_MAGAZINES_DATA = "ACTION_SAVE_MAGAZINES_DATA";
export const ACTION_SAVE_MAGAZINE_DATA = "ACTION_SAVE_MAGAZINE_DATA";

export const ACTION_ADD_MAGAZINE_API_CALL = "ACTION_ADD_MAGAZINE_API_CALL";
export const ACTION_UPDATE_MAGAZINE_API_CALL =
  "ACTION_UPDATE_MAGAZINE_API_CALL";

export const PER_PAGE_MAGAZINE_COUNT = "PER_PAGE_MAGAZINE_COUNT";
export const SAVE_MAGAZINE_FILTER_DATA = "SAVE_MAGAZINE_FILTER_DATA";

export const ACTION_GET_MAGAZINE_AD_KINDS_API_CALL =
  "ACTION_GET_MAGAZINE_AD_KINDS_API_CALL";
export const ACTION_SAVE_MAGAZINE_AD_KINDS_DATA =
  "ACTION_SAVE_MAGAZINE_AD_KINDS_DATA";

export const ACTION_EXPORT_MAGAZINE_API_CALL =
  "ACTION_EXPORT_MAGAZINE_API_CALL";

export const GET_MAGAZINE_META_DATA_API_CALL =
  "GET_MAGAZINE_META_DATA_API_CALL";

export const ACTION_VIEW_MAGAZINE_API_CALL = "ACTION_VIEW_MAGAZINE_API_CALL";

export const SAVE_MAGAZINE_TYPES_DATA = "SAVE_MAGAZINE_TYPES_DATA";

export const ACTION_ADD_MEMBER_API_CALL = "ACTION_ADD_MEMBER_API_CALL";

export const actionLoginApiCall = (data) => ({
  type: LOGIN_API_CALL,
  payload: data,
});

export const actionSaveLoginUserData = (data) => ({
  type: SAVE_LOGIN_USER_DATA,
  payload: data,
});

//user actions

export const actionUserListApiCall = (data) => ({
  type: USER_LIST_API_CALL,
  payload: data,
});

export const actionSaveUserListData = (data) => ({
  type: SAVE_USER_LIST_DATA,
  payload: data,
});

export const actionCreateUserApiCall = (data) => ({
  type: CREATE_USER_API_CALL,
  payload: data,
});

export const actionViewUserApiCall = (data) => ({
  type: VIEW_USER_API_CALL,
  payload: data,
});

export const actionSaveSingleUserdata = (data) => ({
  type: SAVE_SINGLE_USER_DATA,
  payload: data,
});

export const actionDeleteUserApiCall = (data) => ({
  type: DELETE_USER_API_CALL,
  payload: data,
});

export const actionBlockUserApiCall = (data) => ({
  type: BLOCK_USER_API_CALL,
  payload: data,
});

export const settotalUserCount = (data) => ({
  type: TOTAL_USER_COUNT,
  payload: data,
});

export const settotalDiscountcount = (data) => ({
  type: TOTAL_DISCOUNT_COUNT,
  payload: data,
});

export const settotalSubscriptioncount = (data) => ({
  type: TOTAL_SUBSCIPTION_COUNT,
  payload: data,
});
export const setperPageUserCount = (data) => ({
  type: PER_PAGE_USER_COUNT,
  payload: data,
});

export const actionGetOrganizationListSingleUserAPICall = (data) => ({
  type: GET_ORG_LIST_IN_SINGLE_USER_API_CALL,
  payload: data,
});

export const actionSaveOrganizationListSingleUserData = (data) => ({
  type: SAVE_ORG_LIST_IN_SINGLE_USER_DATA,
  payload: data,
});

export const actionViewLogHistoryAPICall = (data) => ({
  type: VIEW_LOG_HISTORY_API_CALL,
  payload: data,
});

export const actionSaveLogHistoryData = (data) => ({
  type: SAVE_LOG_HISTORY_DATA,
  payload: data,
});
//organization actions

export const actionOrganizationGetCitiesApiCall = () => ({
  type: ORGANIZATION_CITIES_GET_API_CALL,
});
export const actionStoreCitiesData = (data) => ({
  type: ACTION_SAVE_CITIES_DATA,
  payload: data,
});

export const actionOrganizationGetZipcode = () => ({
  type: ORGANIZATION_ZIP_CODE,
});

export const actionStoreZipData = (data) => ({
  type: ACTION_SAVE_ZIP_CODE,
  payload: data,
});

export const actionOrganizationListApiCall = (data) => ({
  type: ORGANIZATION_LIST_API_CALL,
  payload: data,
});

export const actionViewOrganizationApiCall = (data) => ({
  type: VIEW_ORGANIZATION_API_CALL,
  payload: data,
});

export const actionSaveSingleOrganizationdata = (data) => ({
  type: SAVE_SINGLE_ORGANIZATION_DATA,
  payload: data,
});

export const actionSaveOrganizationListData = (data) => ({
  type: SAVE_ORGANIZATION_LIST_DATA,
  payload: data,
});

export const actionDeleteOrganizationApiCall = (data) => ({
  type: DELETE_ORGANIZATION_API_CALL,
  payload: data,
});

export const actionRunImportApiCall = (data) => ({
  type: RUN_IMPORT_API_CALL,
  payload: data,
});

export const actionUpdateOrganizationApiCall = (data) => ({
  type: UPDATE_ORGANIZATION_API_CALL,
  payload: data,
});

export const actionBlockOrganizationApiCall = (data) => ({
  type: BLOCK_ORGANIZATION_API_CALL,
  payload: data,
});

export const actionCreateOrganizationApiCall = (data) => ({
  type: CREATE_ORGANIZATION_API_CALL,
  payload: data,
});
export const addTagApiCall = (data) => ({
  type: ADD_TAG_API_CALL,
  payload: data,
});
export const addAudienceApiCall = (data) => ({
  type: ADD_AUDIENCE_API_CALL,
  payload: data,
});
export const addRecipientApiCall = (data) => ({
  type: ADD_RECIPIENT_API_CALL,
  payload: data,
});
export const downloadRecipientApiCall = (data) => ({
  type: DOWNLOAD_ALL_RECIPIENT_API_CALL,
  payload: data,
});
export const getTemplateApiCall = (data) => ({
  type: GET_TEMPLATE_API_CALL,
  payload: data,
});
export const getCreatedTemplateApiCall = (data) => ({
  type: GET_CREATED_TEMPLATE_API_CALL,
  payload: data,
});
export const getCampaignApiCall = (data) => ({
  type: GET_CAMPAIGN_API_CALL,
  payload: data,
});
export const actionAddUserOrgApiCall = (data) => ({
  type: ADD_USER_ORG_API_CALL,
  payload: data,
});
export const actionRemoveUserOrgApiCall = (data) => ({
  type: REMOVE_USER_ORG_API_CALL,
  payload: data,
});

export const settotalOrgCount = (data) => ({
  type: TOTAL_ORG_COUNT,
  payload: data,
});

export const setperPageOrgCount = (data) => ({
  type: PER_PAGE_ORG_COUNT,
  payload: data,
});

export const setperPageSupplierCount = (data) => ({
  type: PER_PAGE_SUPPLIER_COUNT,
  payload: data,
});

export const actionGetPropertyListAPICall = (data) => ({
  type: GET_PROPERTY_LIST_API_CALL,
  payload: data,
});

export const actionSavePropertyListdata = (data) => ({
  type: SAVE_PROPERTY_LIST_DATA,
  payload: data,
});

export const actionUsedEmailFlag = (data) => ({
  type: USED_EMAIL_FLAG,
  payload: data,
});

export const actionAlrearyUserdEmailData = (data) => ({
  type: ALREAFY_USED_EMAIL_DATA,
  payload: data,
});

export const actionremoveJoinedOrganisationApiCall = (data) => ({
  type: REMOVE_JOINED_ORG_API_CALL,
  payload: data,
});

export const settotalListCount = (data) => ({
  type: TOTAL_LIST_COUNT,
  payload: data,
});

export const setperPageListCount = (data) => ({
  type: PER_PAGE_LIST_COUNT,
  payload: data,
});

export const actionChangeAdminOrgApiCall = (data) => ({
  type: CHANGE_ADMIN_ORG_API_CALL,
  payload: data,
});

export const actionUnblockUserApiCall = (data) => ({
  type: UNBLOCK_USER_API_CALL,
  payload: data,
});

export const actionUpdatePropertyVisibleApiCall = (data) => ({
  type: UPDATE_PROPERTY_VISIBLE_API_CALL,
  payload: data,
});

export const actionGetPropertyDetailApiCall = (data) => ({
  type: GET_PROPERTY_DETAIL_API_CALL,
  payload: data,
});

export const actionSaveSupplierTypesData = (data) => ({
  type: SAVE_SUPPLIER_TYPES_DATA,
  payload: data,
});

export const actionSavePriceTableTypesData = (data) => ({
  type: SAVE_PRICETABLE_TYPES_DATA,
  payload: data,
});

export const actionSavePriceLineUnitsData = (data) => ({
  type: SAVE_PRICELINE_UNITS_DATA,
  payload: data,
});

export const actionSaveSinglePropertyData = (data) => ({
  type: SAVE_SINGLE_PROPERTY_DETAIL,
  payload: data,
});

export const actionSaveLocationListData = (data) => ({
  type: SAVE_LOCATION_LIST_DATA,
  payload: data,
});

export const actionSaveTitleListData = (data) => ({
  type: SAVE_TITLE_LIST_DATA,
  payload: data,
});

export const actionSaveAdvertisingSupplierListData = (data) => ({
  type: SAVE_ADVERTISING_SUPPLIER_LIST_DATA,
  payload: data,
});

export const actionEditPropertyStatusApiCall = (data) => ({
  type: EDIT_PROPERTY_STATUS_API_CALL,
  payload: data,
});

export const actiongetPropertyViewsApiCall = (data) => ({
  type: GET_PROPERTY_VIEWS_API_CALL,
  payload: data,
});

export const actionSavePropertyViewData = (data) => ({
  type: SAVE_PROPERTY_VIEWS_DETAIL,
  payload: data,
});

export const actiongetPriceHistoryApiCall = (data) => ({
  type: GET_PRICE_HISTORY_API_CALL,
  payload: data,
});

export const actionSavePriceHistoryData = (data) => ({
  type: SAVE_PRICE_HISTORY_DATA,
  payload: data,
});

export const actionHideAllListingAPICall = (data) => ({
  type: HIDE_ALL_LISTING_OF_ORG_API_CALL,
  payload: data,
});

export const actionSendPasswordLinkAPICall = (data) => ({
  type: SEND_PASSWORD_LINK_API_CALL,
  payload: data,
});

export const actionLangListAPICall = (data) => ({
  type: LANG_LIST_API_CALL,
  payload: data,
});

export const actionSaveLangListData = (data) => ({
  type: SAVE_LANG_LIST_DATA,
  payload: data,
});

export const actionLangLabelListAPICall = (data) => ({
  type: LANG_LABEL_LIST_API_CALL,
  payload: data,
});

export const actionSaveLangLabelListData = (data) => ({
  type: SAVE_LANG_LABEL_LIST_DATA,
  payload: data,
});

export const actionUpdateLangLabelListAPICall = (data) => ({
  type: UPDATE_LANG_LABEL_API_CALL,
  payload: data,
});

export const settotalLangCount = (data) => ({
  type: TOTAL_LANG_COUNT,
  payload: data,
});

export const setperPageLangCount = (data) => ({
  type: PER_PAGE_LANG_COUNT,
  payload: data,
});

export const actionSetLoader = (data) => ({
  type: SET_LOADER,
  payload: data,
});
export const actionOrgLoader = (data) => ({
  type: ORG_LOADER,
  payload: data,
});

export const actionSetLanguage = (data) => ({
  type: SET_LANGUAGE,
  payload: data,
});

export const actionChangeAdminPropertyAPICall = (data) => ({
  type: CHANGE_ADMIN_PROPERTY_API_CALL,
  payload: data,
});

export const actionDeletePropertyAPICall = (data) => ({
  type: DELETE_PROPERTY_API_CALL,
  payload: data,
});

export const actionEditPropertyAPICall = (data) => ({
  type: EDIT_PROPERTY_API_CALL,
  payload: data,
});

export const actionSaveLangFilterData = (data) => ({
  type: SAVE_LANG_FILTER_DATA,
  payload: data,
});

export const actionLogoutApiCall = (data) => ({
  type: LOGOUT_API_CALL,
  payload: data,
});

export const actionSaveUserFilterData = (data) => ({
  type: SAVE_USER_FILTER_DATA,
  payload: data,
});

export const actionSaveDiscountFilterData = (data) => ({
  type: SAVE_DISCOUNT_FILTER_DATA,
  payload: data,
});

export const actionSaveSubscriptionFilterData = (data) => ({
  type: SAVE_SUBSCIPTION_FILTER_DATA,
  payload: data,
});

export const actionSaveOrgFilterData = (data) => ({
  type: SAVE_ORG_FILTER_DATA,
  payload: data,
});

export const actionSaveSupplierFilterData = (data) => ({
  type: SAVE_SUPPLIER_FILTER_DATA,
  payload: data,
});

export const actionSaveListFilterData = (data) => ({
  type: SAVE_LIST_FILTER_DATA,
  payload: data,
});

export const actionApproveOrgApiCall = (data) => ({
  type: APPROVE_ORGANIZATION_API_CALL,
  payload: data,
});

export const actionUnblockOrgApiCall = (data) => ({
  type: UNBLOCK_ORGANIZATION_API_CALL,
  payload: data,
});

export const actionBannerListApiCall = (data) => ({
  type: BANNER_LIST_API_CALL,
  payload: data,
});

export const actionSaveBannerData = (data) => ({
  type: SAVE_BANNER_DATA,
  payload: data,
});
export const actionSaveBannerFilterData = (data) => ({
  type: SAVE_BANNER_FILTER_DATA,
  payload: data,
});

export const actionAddBannerApiCall = (data) => ({
  type: ADD_BANNER_API_CALL,
  payload: data,
});

export const actionDeleteBannerApiCall = (data) => ({
  type: DELETE_BANNER_API_CALL,
  payload: data,
});

export const actionViewBannerApiCall = (data) => ({
  type: VIEW_BANNER_API_CALL,
  payload: data,
});

export const actionSaveSingleBannerData = (data) => ({
  type: SAVE_SINGLE_BANNER_DATA,
  payload: data,
});

export const settotalBannerCount = (data) => ({
  type: TOTAL_BANNER_COUNT,
  payload: data,
});

export const setperPageBannerCount = (data) => ({
  type: PER_PAGE_BANNER_COUNT,
  payload: data,
});

export const actionEmailListApiCall = (data) => ({
  type: EMAIL_LIST_API_CALL,
  payload: data,
});

export const settotalEmailCount = (data) => ({
  type: TOTAL_EMAIL_COUNT,
  payload: data,
});

export const setperPageEmailCount = (data) => ({
  type: PER_PAGE_EMAIL_COUNT,
  payload: data,
});

export const actionSaveEmailData = (data) => ({
  type: SAVE_EMAIL_DATA,
  payload: data,
});

export const actionSaveEmailFilterData = (data) => ({
  type: SAVE_EMAIL_FILTER_DATA,
  payload: data,
});

export const actionViewEmailApiCall = (data) => ({
  type: VIEW_EMAIL_API_CALL,
  payload: data,
});

export const actionSaveSingleEmailData = (data) => ({
  type: SAVE_SINGLE_EMAIL_DATA,
  payload: data,
});

export const actionAddEmailApiCall = (data) => ({
  type: ADD_EMAIL_API_CALL,
  payload: data,
});

export const actionUpdateSingleUserData = (data) => ({
  type: UPDATE_SINGLE_USER_DATA,
  payload: data,
});

export const actionLinkSend = (data) => ({
  type: LINK_SEND,
  payload: data,
});

export const actiongetTranslationListApiCall = (data) => ({
  type: GET_TRANSLATION_LIST_API_CALL,
  payload: data,
});

export const actiongetLocationListApiCall = (data) => ({
  type: GET_LOCATION_LIST_API_CALL,
  payload: data,
});

export const actionGetSupplierMetaDataApiCall = (data) => ({
  type: GET_SUPPLIER_META_DATA_API_CALL,
  payload: data,
});

export const actiongetTitleListApiCall = (data) => ({
  type: GET_TITLE_LIST_API_CALL,
  payload: data,
});

export const actiongetAdvertisingSupplierListApiCall = (data) => ({
  type: GET_ADVERTISING_SUPPLIER_LIST_API_CALL,
  payload: data,
});

export const actionSaveRentPrefixData = (data) => ({
  type: SAVE_RENT_PREFIX_DATA,
  payload: data,
});

export const actionSaveRentPostfixData = (data) => ({
  type: SAVE_RENT_POSTFIX_DATA,
  payload: data,
});

export const actionSaveSellPrefixData = (data) => ({
  type: SAVE_SELL_PREFIX_DATA,
  payload: data,
});

export const actionSaveSellPostfixData = (data) => ({
  type: SAVE_SELL_POSTFIX_DATA,
  payload: data,
});

export const actionSaveStatusData = (data) => ({
  type: SAVE_STATUS_DATA,
  payload: data,
});

export const actionUpdateJobTitleApiCall = (data) => ({
  type: UPDATE_JOB_TITLE_API_CALL,
  payload: data,
});

export const actionChangeUserFromPropertyApiCall = (data) => ({
  type: CHANGE_USER_FROM_PROPERTY_API_CALL,
  payload: data,
});

export const actionGetEmployeeJobTitleApiCall = (data) => ({
  type: ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL,
  payload: data,
});

export const actionSaveEmployeeJobTitle = (data) => ({
  type: ACTION_SAVE_EMPLOYEE_JOB_TITLE,
  payload: data,
});

export const actionChangeImportStatusApiCall = (data) => ({
  type: CHANGE_IMPORT_STATUS_API_CALL,
  payload: data,
});
export const actionGetSiteSettingsApiCall = (data) => ({
  type: ACTION_GET_SITE_SETTINGS_API_CALL,
  payload: data,
});

export const actionSaveGetSiteSettings = (data) => ({
  type: ACTION_SAVE_GET_SITE_SETTINGS,
  payload: data,
});

export const actionUpdateSiteSettingsApiCall = (data) => ({
  type: ACTION_UPDATE_SITE_SETTINGS_API_CALL,
  payload: data,
});

export const actionChangeImportProfileStatusApiCall = (data) => ({
  type: CHANGE_IMPORT_PROFILE_STATUS_API_CALL,
  payload: data,
});

export const actionMasterTableGetTranslationColumnApiCall = (data) => ({
  type: ACTION_MASTER_TABLE_GET_TRANSLATION_COLUMN_API_CALL,
  payload: data,
});

export const actionSaveMasterTableGetTranslationColumn = (data) => ({
  type: ACTION_SAVE_MASTER_TABLE_GET_TRANSLATION_COLUMN,
  payload: data,
});

export const actionGetTranslationApiCall = (data) => ({
  type: ACTION_GET_TRANSLATION_API_CALL,
  payload: data,
});

export const actionSaveGetTranslation = (data) => ({
  type: ACTION_SAVE_GET_TRANSLATION,
  payload: data,
});

export const actionAddTranslationApiCall = (data) => ({
  type: ACTION_ADD_TRANSLATION_API_CALL,
  payload: data,
});

export const actionSavePerPageMasterTableCount = (data) => ({
  type: PER_PAGE_MASTER_TABLE_COUNT,
  payload: data,
});

export const actionSaveTotalMasterTableCount = (data) => ({
  type: SAVE_TOTAL_MASTER_TABLE_COUNT,
  payload: data,
});

export const actionSaveMasterTableFilterData = (data) => ({
  type: SAVE_MASTER_TABLE_FILTER_DATA,
  payload: data,
});

export const actionEditPropertyDocumentsApiCall = (data) => ({
  type: ACTION_EDIT_PROPERTY_DOCUMENTS_API_CALL,
  payload: data,
});

export const actionRemovePropertyImagesApiCall = (data) => ({
  type: ACTION_REMOVE_PROPERTY_IMAGES_API_CALL,
  payload: data,
});

//discount apis
export const actionGetDiscountAndExtraApiCall = (data) => ({
  type: ACTION_GET_DISCOUNT_AND_EXTRAS_API_CALL,
  payload: data,
});
export const actionSaveDiscountAndExtras = (data) => ({
  type: ACTION_SAVE_DISCOUNT_AND_EXTRAS,
  payload: data,
});
export const actionDeleteDiscountAndExtrasApiCall = (data) => ({
  type: ACTION_DELETE_DISCOUNT_AND_EXTRAS_API_CALL,
  payload: data,
});
export const actionAddDiscountAndExtrasApiCall = (data) => ({
  type: ACTION_ADD_DISCOUNT_AND_EXTRAS_API_CALL,
  payload: data,
});
export const actionSaveSingleDiscountData = (data) => ({
  type: ACTION_SAVE_SINGLE_DISCOUNT_DATA,
  payload: data,
});

//credit apis

export const actionAddCreditApiCall = (data) => ({
  type: ACTION_ADD_CREDIT_API_CALL,
  payload: data,
});

//subsciption apis

export const actionGetSubscriptionApiCall = (data) => ({
  type: ACTION_GET_SUBSCIPTION_API_CALL,
  payload: data,
});
export const actionSaveSubscription = (data) => ({
  type: ACTION_SAVE_SUBSCIPTION,
  payload: data,
});
export const actionDeleteSubscriptionApiCall = (data) => ({
  type: ACTION_DELETE_SUBSCIPTION_API_CALL,
  payload: data,
});
export const actionCreateSubscriptionApiCall = (data) => ({
  type: ACTION_CREATE_SUBSCRIPTION_API_CALL,
  payload: data,
});
export const actionSaveSingleSubscriptionData = (data) => ({
  type: ACTION_SAVE_SINGLE_SUBSCIPTION_DATA,
  payload: data,
});
export const actionEditSingleSubscriptionApiCall = (data) => ({
  type: ACTION_EDIT_SINGLE_SUBSCIPTION_API_CALL,
  payload: data,
});
export const actionEditActiveSubscriptionApiCall = (data) => ({
  type: ACTION_EDIT_ACTIVE_SUBSCIPTION_API_CALL,
  payload: data,
});

export const actionRemoveSubscriptionPaymentOptionsApiCall = (data) => ({
  type: ACTION_REMOVE_SUBSCIPTION_PAYMENT_OPTIONS_API_CALL,
  payload: data,
});
export const actionRemoveSubscriptionDiscountsExtrasOptionsApiCall = (
  data
) => ({
  type: ACTION_REMOVE_SUBSCIPTION_DISCOUNTS_EXTRAS_OPTIONS_API_CALL,
  payload: data,
});

export const actionAddSubscriptionImageApiCall = (data) => ({
  type: ACTION_ADD_SUBSCIPTION_IMAGE_API_CALL,
  payload: data,
});

export const actionChangeSubscriptionProductOrderApiCall = (data) => ({
  type: ACTION_CHANGE_SUBSCIPTION_ORDER_API_CALL,
  payload: data,
});

export const actionGetAllSubscriptionByOrgApiCall = (data) => ({
  type: ACTION_GET_ALL_SUBSCIPTION_BY_ORG_API_CALL,
  payload: data,
});

export const actionSaveAllSubscriptionListByOrg = (data) => ({
  type: ACTION_SAVE_ALL_SUBSCIPTION_LIST,
  payload: data,
});

export const actionGetAllPaymentOptionApiCall = (data) => ({
  type: ACTION_GET_ALL_PAYMENT_OPTION_API_CALL,
  payload: data,
});

export const actionGetAllClassificationsApiCall = (data) => ({
  type: ACTION_GET_ALL_CLASSIFICATIONS_API_CALL,
  payload: data,
});

export const actionSaveAllPaymentOptionApiCall = (data) => ({
  type: ACTION_SAVE_ALL_PAYMENT_OPTION,
  payload: data,
});
// export const actionSaveAllTagsApiCall = (data) => ({
//   type: ACTION_SAVE_ALL_TAGS_OPTION,
//   payload: data,
// });
export const actionSaveAllProvinceApiCall = (data) => ({
  type: ACTION_SAVE_ALL_PROVINCE_OPTION,
  payload: data,
});
export const actionGetAllCityApiCall = (data) => ({
  type: ACTION_GET_ALL_CITY_OPTION,
  payload: data,
});
export const actionSaveAllCityApiCall = (data) => ({
  type: ACTION_SAVE_ALL_CITY_OPTION,
  payload: data,
});
export const actionGetAllProvinceApiCall = (data) => ({
  type: ACTION_GET_ALL_PROVINCE_OPTION,
  payload: data,
});
export const actionSaveAllClassificationsApiCall = (data) => ({
  type: ACTION_SAVE_ALL_CLASSIFICATIONS_OPTION,
  payload: data,
});

export const actionCreateManualPaymentApiCall = (data) => ({
  type: ACTION_CREATE_MANUAL_PAYMENT_API_CALL,
  payload: data,
});

export const actionStopSubscriptionApiCall = (data) => ({
  type: ACTION_STOP_SUBSCRIPTION_API_CALL,
  payload: data,
});

export const actionEditSubscriptionInOrgApiCall = (data) => ({
  type: ACTION_EDIT_SUBSCRIPTION_IN_ORG_API_CALL,
  payload: data,
});

export const actionGetBranchTableSystemApiCall = (data) => ({
  type: ACTION_GET_BRANCH_TABLE_DATA_API_CALL,
  payload: data,
});

export const actionSaveBranchTableSystemApiCall = (data) => ({
  type: ACTION_SAVE_BRANCH_TABLE_DATA,
  payload: data,
});
export const getBranchApiCall = (data) => ({
  type: ACTION_GET_BRANCH,
  payload: data,
});

export const saveBranchApiCallData = (data) => ({
  type: ACTION_SAVE_BRANCH,
  payload: data,
});

export const actionAddBranchData = (data) => ({
  type: ACTION_ADD_BRANCH_DATA,
  payload: data,
});

export const actionSaveBranchData = (data) => ({
  type: ACTION_ADD_BRANCH_DATA,
  payload: data,
});

export const actionBanchNestedGetApi = (data) => ({
  type: ACTION_GET_BRANCH_NESTED_DATA,
  payload: data,
});

export const actionBranchNestedSaveData = (data) => ({
  type: ACTION_STORE_BRANCH_NESTED_DATA,
  payload: data,
});

export const actionForCloseCanvas = (data) => ({
  type: ACTION_GLBOAL_STATE_FOR_CLOSE_CANVAS,
  payload: data,
});

export const actionDeleteBranch = (data) => ({
  type: ACTION_REMOVE_BRANCH_DATA,
  payload: data,
});

export const actionEditBranch = (data) => ({
  type: ACTION_EDIT_BRANCH_DATA,
  payload: data,
});
//
export const actionClassificationNestedGetApi = (data) => ({
  type: ACTION_NESTED_CLASSIFICATION_DATA,
  payload: data,
});

export const actionClassificationNestedStoreAPi = (data) => ({
  type: ACTION_STORE_NESTED_CLASSIFICATION_DATA,
  payload: data,
});

export const actionClassificationAddApi = (data) => ({
  type: ACTION_ADD_CLASSIFICATION_DATA,
  payload: data,
});
export const actionClassificationGetTableApi = (data) => ({
  type: ACTION_GET_TABLE_CLASSIFICATION_DATA,
  payload: data,
});

export const actionClassificationStoreTableApi = (data) => ({
  type: ACTION_STORE_TABLE_CLASSIFICATION_DATA,
  payload: data,
});

export const actionClassificationRemoveData = (data) => ({
  type: ACTION_REMOVE_CLASSIFICATION_DATA,
  payload: data,
});

export const actionSetTotalBranchCount = (data) => ({
  type: TOTAL_BRANCH_COUNT,
  payload: data,
});

export const actionPerPageBranchCount = (data) => ({
  type: PER_PAGE_BRANCH_COUNT,
  payload: data,
});

export const actionSetTotalClassificationCount = (data) => ({
  type: TOTAL_CLASSIFICATION_COUNT,
  payload: data,
});

export const actionPerPageClassificationCount = (data) => ({
  type: PER_PAGE_CLASSIFICATION_COUNT,
  payload: data,
});
//contact actions
export const actionAddContactApiCall = (data) => ({
  type: ACTION_ADD_CONTACT_API_CALL,
  payload: data,
});

export const actionDeleteContactApiCall = (data) => ({
  type: ACTION_DELETE_CONTACT_API_CALL,
  payload: data,
});

//country module
export const actionGetCountriesTableSystemApiCall = (data) => ({
  type: ACTION_GET_COUNTRIES_TABLE_DATA_API_CALL,
  payload: data,
});

export const actionSaveCountriesTableData = (data) => ({
  type: ACTION_SAVE_COUNTRIES_TABLE_DATA,
  payload: data,
});

export const actionSetTotalCountriesCount = (data) => ({
  type: ACTION_SAVE_TOTAL_COUNTRIES_COUNT,
  payload: data,
});

export const actionCountriesAddApiCall = (data) => ({
  type: ACTION_ADD_COUNTRIES_ADD_API_CALL,
  payload: data,
});
export const actionRemoveCountriesDataApiCall = (data) => ({
  type: ACTION_REMOVE_COUNTRIES_DATA_API_CALL,
  payload: data,
});

//Municipality module
export const actionGetMunicipalityTableSystemApiCall = (data) => ({
  type: ACTION_GET_MUNICIPALITY_TABLE_DATA_API_CALL,
  payload: data,
});

export const actionSaveMunicipalityTableData = (data) => ({
  type: ACTION_SAVE_MUNICIPALITY_TABLE_DATA,
  payload: data,
});

export const actionSetTotalMunicipalityCount = (data) => ({
  type: ACTION_SAVE_TOTAL_MUNICIPALITY_COUNT,
  payload: data,
});

export const actionAddMunicipalityDataApiCall = (data) => ({
  type: ACTION_ADD_MUNICIPALITY_DATA_API_CALL,
  payload: data,
});

export const actionDeleteMunicipalityDataApiCall = (data) => ({
  type: ACTION_DELETE_MUNICIPALITY_DATA_API_CALL,
  payload: data,
});

export const actionAddAgencyBranchesApiCall = (data) => ({
  type: ACTION_ADD_AGENCY_BRANCH_DATA_API_CALL,
  payload: data,
});

export const actionAddAgencyClassificationApiCall = (data) => ({
  type: ACTION_ADD_AGENCY_CLASSIFICATION_DATA_API_CALL,
  payload: data,
});
//district module
export const actionGetDistrictTableSystemApiCall = (data) => ({
  type: ACTION_GET_DISTRICT_TABLE_DATA_API_CALL,
  payload: data,
});

export const actionSaveDistrictTableData = (data) => ({
  type: ACTION_SAVE_DISTRICT_TABLE_DATA,
  payload: data,
});

export const actionSetTotalDistrictCount = (data) => ({
  type: ACTION_SAVE_TOTAL_DISTRICT_COUNT,
  payload: data,
});

export const actionAddDistrictDataApiCall = (data) => ({
  type: ACTION_ADD_DISTRICT_DATA_API_CALL,
  payload: data,
});

export const actionDeleteDistrictDataApiCall = (data) => ({
  type: ACTION_DELETE_DISTRICT_DATA_API_CALL,
  payload: data,
});

//neighbourhood module
export const actionGetNeighbourhoodTableSystemApiCall = (data) => ({
  type: ACTION_GET_NEIGHBOURHOOD_TABLE_DATA_API_CALL,
  payload: data,
});

export const actionSaveNeighbourhoodTableData = (data) => ({
  type: ACTION_SAVE_NEIGHBOURHOOD_TABLE_DATA,
  payload: data,
});

export const actionSetTotalNeighbourhoodCount = (data) => ({
  type: ACTION_SAVE_TOTAL_NEIGHBOURHOOD_COUNT,
  payload: data,
});

export const actionAddNeighbourhoodDataApiCall = (data) => ({
  type: ACTION_ADD_NEIGHBOURHOOD_DATA_API_CALL,
  payload: data,
});

export const actionDeleteNeighbourhoodDataApiCall = (data) => ({
  type: ACTION_DELETE_NEIGHBOURHOOD_DATA_API_CALL,
  payload: data,
});

//CountryPart module
export const actionGetCountryPartTableSystemApiCall = (data) => ({
  type: ACTION_GET_COUNTRYPART_TABLE_DATA_API_CALL,
  payload: data,
});

export const actionSaveCountryPartTableData = (data) => ({
  type: ACTION_SAVE_COUNTRYPART_TABLE_DATA,
  payload: data,
});

export const actionSetTotalCountryPartCount = (data) => ({
  type: ACTION_SAVE_TOTAL_COUNTRYPART_COUNT,
  payload: data,
});

export const actionCountryPartAddApiCall = (data) => ({
  type: ACTION_ADD_COUNTRYPART_DATA_API_CALL,
  payload: data,
});
export const actionDeleteCountryPartDataApiCall = (data) => ({
  type: ACTION_DELETE_COUNTRYPART_DATA_API_CALL,
  payload: data,
});
//city actions
export const actionGetCitySystemApiCall = (data) => ({
  type: ACTION_GET_CITY_API_CALL,
  payload: data,
});

export const actionSaveCityData = (data) => ({
  type: ACTION_SAVE_CITY_DATA,
  payload: data,
});

export const actionSetTotalCityCount = (data) => ({
  type: ACTION_SAVE_TOTAL_CITY_COUNT,
  payload: data,
});

export const actionAddCityApiCall = (data) => ({
  type: ACTION_ADD_CITY_API_CALL,
  payload: data,
});

export const actionDeleteCityApiCall = (data) => ({
  type: ACTION_DELETE_CITY_API_CALL,
  payload: data,
});

//province
export const actionGetProvinceApiCall = (data) => ({
  type: ACTION_GET_PROVINCE_API_CALL,
  payload: data,
});

export const actionSaveProvinceData = (data) => ({
  type: ACTION_SAVE_PROVINCE_DATA,
  payload: data,
});

export const actionSetTotalProvinceCount = (data) => ({
  type: ACTION_SAVE_TOTAL_PROVINCE_COUNT,
  payload: data,
});

export const actionProvinceAddApiCall = (data) => ({
  type: ACTION_ADD_PROVINCE_DATA_API_CALL,
  payload: data,
});
export const actionDeleteProvinceDataApiCall = (data) => ({
  type: ACTION_DELETE_PROVINCE_DATA_API_CALL,
  payload: data,
});
//zipcode actions
export const actionGetZipcodeApiCall = (data) => ({
  type: ACTION_GET_ZIPCODE_API_CALL,
  payload: data,
});

export const actionSaveZipcodeData = (data) => ({
  type: ACTION_SAVE_ZIPCODE_DATA,
  payload: data,
});

export const actionSetTotalZipcodeCount = (data) => ({
  type: ACTION_SAVE_TOTAL_ZIPCODE_COUNT,
  payload: data,
});

export const actionAddZipcodeApiCall = (data) => ({
  type: ACTION_ADD_ZIPCODE_API_CALL,
  payload: data,
});

export const actionDeleteZipcodeApiCall = (data) => ({
  type: ACTION_DELETE_ZIPCODE_API_CALL,
  payload: data,
});
//contact log

export const actionGetContactLogApiCall = (data) => ({
  type: ACTION_GET_CONTACT_LOG_API_CALL,
  payload: data,
});

export const actionSaveContactLogData = (data) => ({
  type: ACTION_SAVE_CONTACT_LOG_DATA,
  payload: data,
});

export const actionAddContactLogApiCall = (data) => ({
  type: ACTION_ADD_CONTACT_LOG_API_CALL,
  payload: data,
});

export const actionDeleteContactLogApiCall = (data) => ({
  type: ACTION_DELETE_CONTACT_LOG_API_CALL,
  payload: data,
});

export const actionSetButtonLoader = (data) => ({
  type: SET_BUTTON_LOADER,
  payload: data,
});
export const actionGetAutoSuggestCityApiCall = (data) => ({
  type: ACTION_AUTO_SUGGEST_CITY_API_CALL,
  payload: data,
});

export const actionSaveAutoSuggestCityData = (data) => ({
  type: ACTION_SAVE_AUTO_SUGGEST_CITY_DATA,
  payload: data,
});

export const actionAddOrgAddressApiCall = (data) => ({
  type: ACTION_ADD_ADDRESS_ORG_API_CALL,
  payload: data,
});

export const actionDeleteOrgAddressApiCall = (data) => ({
  type: ACTION_DELETE_ADDRESS_ORG_API_CALL,
  payload: data,
});
export const actionAddLocationApiCall = (data) => ({
  type: ACTION_ADD_LOCATION_API_CALL,
  payload: data,
});

export const actionDeleteLocationApiCall = (data) => ({
  type: ACTION_DELETE_LOCATION_API_CALL,
  payload: data,
});
export const actionAddPriceTableApiCall = (data) => ({
  type: ACTION_ADD_PRICE_TABLE_API_CALL,
  payload: data,
});

export const actionDeletePriceTableApiCall = (data) => ({
  type: ACTION_DELETE_PRICE_TABLE_API_CALL,
  payload: data,
});
export const actionGetTranslationColumnApiCall = (data) => ({
  type: ACTION_GET_TRANSLATION_COLUMN_API_CALL,
  payload: data,
});

export const actionSaveTranslationColumn = (data) => ({
  type: ACTION_SAVE_TRANSLATION_COLUMN,
  payload: data,
});

export const actionSetTotalTranslationColumnCount = (data) => ({
  type: ACTION_SAVE_TOTAL_TRANSLATION_COLUMN_COUNT,
  payload: data,
});
export const actionSaveAddressesData = (data) => ({
  type: SAVE_ADDRESSES_DATA,
  payload: data,
});

export const actionAddInternalBannerApiCall = (data) => ({
  type: ACTION_ADD_INTERNAL_BANNER_API_CALL,
  payload: data,
});

export const actionDeleteInternalBannerApiCall = (data) => ({
  type: ACTION_DELETE_INTERNAL_BANNER_API_CALL,
  payload: data,
});

export const actionGetInternalBannerApiCall = (data) => ({
  type: ACTION_GET_INTERNAL_BANNER_API_CALL,
  payload: data,
});

export const actionSaveInternalBannerApiCall = (data) => ({
  type: ACTION_SAVE_INTERNAL_BANNER_DATA,
  payload: data,
});
export const actionAddEditTranslationApiCall = (data) => ({
  type: ACTION_ADD_EDIT_TRANSLATION_API_CALL,
  payload: data,
});

export const actionSaveTranslationColumnFilter = (data) => ({
  type: ACTION_SAVE_TRANSLATION_COLUMN_FILTER,
  payload: data,
});

export const actionSaveTranslationLanguageFilter = (data) => ({
  type: ACTION_SAVE_TRANSLATION_LANGUAGE_FILTER,
  payload: data,
});

export const actionCityInfoApiCall = (data) => ({
  type: ACTION_CITY_INFO_API_CALL,
  payload: data,
});

export const actionSaveCityInfo = (data) => ({
  type: ACTION_CITY_INFO_SAVE_DATA,
  payload: data,
});
export const actionGetVatApiCall = (data) => ({
  type: ACTION_GET_VAT_API_CALL,
  payload: data,
});

export const actionSaveVatData = (data) => ({
  type: ACTION_SAVE_VAT_DATA,
  payload: data,
});

export const actionUpdateVatData = (data) => ({
  type: ACTION_UPDATE_VAT_DATA,
  payload: data,
});
export const actionSetTotalVatDataCount = (data) => ({
  type: ACTION_SAVE_TOTAL_VAT_DATA_COUNT,
  payload: data,
});
export const actionContactLinkingToOrgApiCall = (data) => ({
  type: CONTACT_LINKING_TO_ORG_API_CALL,
  payload: data,
});
export const actionGetSubjectApiCall = (data) => ({
  type: ACTION_POST_SUBJECT_API_CALL,
  payload: data,
});
export const actionGetAllCampaignApiCall = (data) => ({
  type: ACTION_GET_ALL_CAMPAIGN_API_CALL,
  payload: data,
});

export const actionGetBannerUrlApiCall = (data) => ({
  type: ACTION_GET_BANNER_URL_API_CALL,
  payload: data,
});

export const actionSaveBannerUrlData = (data) => ({
  type: ACTION_SAVE_BANNER_URL_DATA,
  payload: data,
});

export const actionBannerCampaignsApiCall = (data) => ({
  type: BANNER_CAMPAIGNS_API_CALL,
  payload: data,
});

export const actionSaveBannerCampaignsListing = (data) => ({
  type: ACTION_SAVE_BANNER_CAMPAIGNS_LISTING,
  payload: data,
});

export const actionSaveBannerCampaignData = (data) => ({
  type: ACTION_SAVE_BANNER_CAMPAIGN_DATA,
  payload: data,
});

export const actionDeletePropertyBannerApiCall = (data) => ({
  type: ACTION_DELETE_PROPERTY_BANNER_API_CALL,
  payload: data,
});

export const actionViewPropertyBannerApiCall = (data) => ({
  type: ACTION_VIEW_PROPERTY_BANNER_API_CALL,
  payload: data,
});

export const actionDeleteSupplierApiCall = (data) => ({
  type: ACTION_DELETE_SUPPLIER_API_CALL,
  payload: data,
});

export const actionViewSupplierApiCall = (data) => ({
  type: ACTION_VIEW_SUPPLIER_API_CALL,
  payload: data,
});

export const actionSaveDeleteSupplier = (data) => ({
  type: ACTION_SAVE_DELETE_SUPPLIER,
  payload: data,
});

export const actionSuppliersApiCall = (data) => ({
  type: SUPPLIERS_API_CALL,
  payload: data,
});

export const actionSaveSuppliersData = (data) => ({
  type: ACTION_SAVE_SUPPLIERS_DATA,
  payload: data,
});

export const actionSaveSupplierData = (data) => ({
  type: ACTION_SAVE_SUPPLIER_DATA,
  payload: data,
});

export const actionAddSupplierApiCall = (data) => ({
  type: ADD_SUPPLIER_API_CALL,
  payload: data,
});

export const actionUpdateSupplierApiCall = (data) => ({
  type: UPDATE_SUPPLIER_API_CALL,
  payload: data,
});

export const actionSaveDeletePropertyBanner = (data) => ({
  type: ACTION_SAVE_DELETE_PROPERTY_BANNER,
  payload: data,
});

export const actionSaveBannerDetails = (data) => ({
  type: ACTION_SAVE_BANNER_DETAILS,
  payload: data,
});

export const actionMakeBannerStopApiCall = (data) => ({
  type: MAKE_BANNER_STOP_API_CALL,
  payload: data,
});

export const actionInvoicesApiCall = (data) => ({
  type: INVOICES_API_CALL,
  payload: data,
});

export const actionSaveInvoiceListing = (data) => ({
  type: ACTION_SAVE_INVOICE_LISTING,
  payload: data,
});

export const actionSaveInvoiceData = (data) => ({
  type: ACTION_SAVE_INVOICE_DATA,
  payload: data,
});
export const actionSaveToken = (data) => ({
  type: ACTION_SAVE_TOKEN,
  payload: data,
});

export const actionDeleteInvoiceApiCall = (data) => ({
  type: ACTION_DELETE_INVOICE_API_CALL,
  payload: data,
});

export const actionViewInvoiceApiCall = (data) => ({
  type: ACTION_VIEW_INVOICE_API_CALL,
  payload: data,
});
export const actionViewTokenApiCall = (data) => ({
  type: ACTION_VIEW_TOKEN_API_CALL,
  payload: data,
});

export const actionSaveDeleteInvoice = (data) => ({
  type: ACTION_SAVE_DELETE_INVOICE,
  payload: data,
});

export const actionSaveInvoiceDetails = (data) => ({
  type: ACTION_SAVE_INVOICE_DETAILS,
  payload: data,
});

export const actionHoldInvoiceApiCall = (data) => ({
  type: HOLD_INVOICE_API_CALL,
  payload: data,
});

export const actionResendInvoiceApiCall = (data) => ({
  type: RESEND_INVOICE_API_CALL,
  payload: data,
});

export const actionSaveCreatedTags = (data) => ({
  type: ACTION_SAVE_CREATED_TAGS,
  payload: data,
});
export const actionSaveCreatedAudience = (data) => ({
  type: ACTION_SAVE_CREATED_AUDIENCE,
  payload: data,
});
export const actionCreateNewCLick = (data) => ({
  type: ACTION_CREATE_NEW,
  payload: data,
});
export const actionSaveSubject = (data) => ({
  type: ACTION_SAVE_SUBJECT,
  payload: data,
});
export const actionEmptySubjectsData = () => ({
  type: ACTION_EMPTY_SUBJECTS_DATA,
});
export const actionSaveCreatedRecipients = (data) => ({
  type: ACTION_SAVE_CREATED_RECIPIENTS,
  payload: data,
});
export const actionSaveDowloadedRecipients = (data) => ({
  type: ACTION_SAVE_DOWNLOADED_RECIPIENTS,
  payload: data,
});
export const actionSaveAllCampaign = (data) => ({
  type: ACTION_SAVE_ALL_CAMPAIGN,
  payload: data,
});
export const actionEmptyContent = () => ({
  type: ACTION_EMPTY_CONTENT,
});
export const actionEmptyAudienceData = () => ({
  type: ACTION_EMPTY_AUDIENCE_DATA,
});
export const actionSaveCreatedTemplates = (data) => ({
  type: ACTION_SAVE_CREATED_TEMPLATES,
  payload: data,
});
export const actionSaveNewCreatedTemplates = (data) => ({
  type: ACTION_SAVE_NEW_CREATED_TEMPLATES,
  payload: data,
});
export const actionSaveNewCreatedCampaign = (data) => ({
  type: ACTION_SAVE_NEW_CREATED_CAMPAIGN,
  payload: data,
});

// TAGS

export const actionGetAllTagsApiCall = (data) => ({
  type: ACTION_GET_ALL_TAGS_API_CALL,
  payload: data,
});

export const actionSaveAllTagsApiCall = (data) => ({
  type: ACTION_SAVE_ALL_TAGS_OPTION,
  payload: data,
});

export const updateTagsRequest = (data) => {
  return {
    type: ACTION_UPDATE_TAGS_SUCCESS,
    payload: data,
  };
};

export const deleteTagRequest = (id) => {
  return {
    type: ACTION_DELETE_TAG_SUCCESS,
    payload: id,
  };
};

//TEMPLATES

export const actionGetAllTemplatesApiCall = (data) => ({
  type: ACTION_GET_ALL_TEMPLATE_API_CALL,
  payload: data,
});

export const actionSaveAllTemplatesApiCall = (data) => ({
  type: ACTION_SAVE_ALL_TEMPLATE_OPTION,
  payload: data,
});

export const updateTemplateRequest = (data) => {
  return {
    type: ACTION_UPDATE_TEMPLATE_SUCCESS,
    payload: data,
  };
};

export const deleteTemplateRequest = (id) => {
  return {
    type: ACTION_DELETE_TEMPLATE_SUCCESS,
    payload: id,
  };
};
export const deleteCampaignRequest = (id) => {
  return {
    type: ACTION_DELETE_CAMPAIGN_SUCCESS,
    payload: id,
  };
};

//MAGAZINES
export const actionGetMagazinesApiCall = (data) => ({
  type: ACTION_GET_MAGAZINES_API_CALL,
  payload: data,
});

export const actionSaveMagazinesData = (data) => ({
  type: ACTION_SAVE_MAGAZINES_DATA,
  payload: data,
});

export const actionGetMagazineApiCall = (data) => ({
  type: ACTION_GET_MAGAZINE_API_CALL,
  payload: data,
});

export const actionExportMagazineApiCall = (data) => ({
  type: ACTION_EXPORT_MAGAZINE_API_CALL,
  payload: data,
});

export const actionSaveMagazineData = (data) => ({
  type: ACTION_SAVE_MAGAZINE_DATA,
  payload: data,
});

export const actionAddMagazineApiCall = (data) => ({
  type: ACTION_ADD_MAGAZINE_API_CALL,
  payload: data,
});

export const actionUpdateMagazineApiCall = (data) => ({
  type: ACTION_UPDATE_MAGAZINE_API_CALL,
  payload: data,
});

export const actionDeleteMagazineApiCall = (data) => ({
  type: ACTION_DELETE_MAGAZINE_API_CALL,
  payload: data,
});

export const actionGetMagazineAdKindsApiCall = (data) => ({
  type: ACTION_GET_MAGAZINE_AD_KINDS_API_CALL,
  payload: data,
});

export const actionSaveMagazineAdKindsData = (data) => ({
  type: ACTION_SAVE_MAGAZINE_AD_KINDS_DATA,
  payload: data,
});

export const actionViewMagazineApiCall = (data) => ({
  type: ACTION_VIEW_MAGAZINE_API_CALL,
  payload: data,
});

export const actionSaveDeleteMagazine = (data) => ({
  type: ACTION_SAVE_DELETE_MAGAZINE,
  payload: data,
});

export const actionMagazinesApiCall = (data) => ({
  type: ACTION_GET_MAGAZINES_API_CALL,
  payload: data,
});

export const actionGetMagazineMetaDataApiCall = (data) => ({
  type: GET_MAGAZINE_META_DATA_API_CALL,
  payload: data,
});

export const setperPageMagazineCount = (data) => ({
  type: PER_PAGE_MAGAZINE_COUNT,
  payload: data,
});

export const actionSaveMagazineTypesData = (data) => ({
  type: SAVE_MAGAZINE_TYPES_DATA,
  payload: data,
});

export const actionAddMembersApiCall = (data) => ({
  type: ACTION_ADD_MEMBER_API_CALL,
  payload: data,
});
